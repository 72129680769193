import api from "../common/api";

const getAll = (type) => api.get(`/user/${type}/by-type`);

const get = (id) => api.get(`/user/${id}`);

const create = (data) => api.post("/user", data);

const update = (data) => api.put(`/user/${data.id}`, data);

const remove = (id) => api.delete(`/user/${id}`);

const searchZipCode = (value) => api.get(`/user/search/zip-code/${value}`);

const importPJ = (data) => api.post("/user/import/pj/", data);

const importPF = (data) => api.post(`/user/import/pf`, data);

const userService = {
  getAll,
  get,
  create,
  update,
  remove,
  searchZipCode,
  importPF,
  importPJ,
};

export default userService;
