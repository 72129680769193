import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import DataGrid from "../../components/dataGrid";
import { getErrorMessage, percentFormatter } from "../../utils";
import moment from "moment";
import couponService from "../../services/coupon";
import { useDispatch } from "react-redux";
import { endLoading, startLoading } from "../../store/loadingSlice";

const CouponView = () => {
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loadingData, setLoadingData] = useState(false);
  const dispatch = useDispatch();
  const columns = [
    {
      field: "startDate",
      headerName: "Data de início",
      minWidth: 150,
      flex: 1,
      type: "dateTime",
      valueFormatter: ({ value }) => moment(value).format("DD/MM/YYYY HH:mm"),
    },
    {
      field: "endDate",
      headerName: "Data de finalização",
      minWidth: 150,
      flex: 1,
      type: "dateTime",
      valueFormatter: ({ value }) => moment(value).format("DD/MM/YYYY HH:mm"),
    },
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "code",
      headerName: "Codígo",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "percentage",
      headerName: "Desconto",
      flex: 1,
      minWidth: 150,
      type: "number",
      valueFormatter: ({ value }) => percentFormatter(value / 100),
    },
  ];

  const getData = useCallback(async () => {
    try {
      setLoadingData(true);
      const { data } = await couponService.getAll();
      setData(data);
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      setLoadingData(false);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onClickDelete = async (id) => {
    try {
      dispatch(startLoading());
      await couponService.remove(id);
      enqueueSnackbar("Remoção realizada com sucesso", { variant: "success" });
      getData();
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <DataGrid
      rows={data}
      columns={columns}
      loading={loadingData}
      editTo={(id) => `form/${id}`}
      onClickDelete={onClickDelete}
    />
  );
};

export default CouponView;
