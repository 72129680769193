import { Container, Typography } from "@mui/material";

const NotFoundView = ({ hasHeader = false }) => {
  return (
    <Container
      fixed
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: (theme) =>
          hasHeader
            ? `calc(100vh - ${theme.headerHeight}px - (${theme.spacing(
                3
              )} * 2))`
            : "100vh",
      }}
    >
      <Typography variant="h1">404</Typography>
      <Typography variant="body1">Página não encontrada</Typography>
    </Container>
  );
};

export default NotFoundView;
