import api from "../common/api";

const getAll = () => api.get("/product");

const get = (id) => api.get(`/product/${id}`);

const getByBrand = (id) => api.get(`/product/brand/${id}`);

const create = (data) => api.post("/product", data);

const update = (data, id) => api.put(`/product/${id}`, data);

const remove = (id) => api.delete(`/product/${id}`);

const importAll = (data) => api.post(`/product/import`, data);

const productService = {
  getAll,
  get,
  getByBrand,
  create,
  update,
  remove,
  importAll,
};

export default productService;
