import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import DataGrid from "../../components/dataGrid";
import { useDispatch } from "react-redux";
import { endLoading, startLoading } from "../../store/loadingSlice";
import { getErrorMessage } from "../../utils";
import restricaoService from "../../services/restricao";
import { useParams } from "react-router-dom";

const RestricaoView = () => {
  const { idBrand } = useParams();
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loadingData, setLoadingData] = useState(false);
  const dispatch = useDispatch();
  const columns = [
    {
      field: "desc",
      headerName: "Descrição",
      flex: 1,
      minWidth: 150,
    },
  ];

  const getData = useCallback(async () => {
    try {
      setLoadingData(true);
      const { data } = await restricaoService.getAll(idBrand);
      setData(data);
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      setLoadingData(false);
    }
  }, [enqueueSnackbar, idBrand]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onClickDelete = async (id) => {
    try {
      dispatch(startLoading());
      await restricaoService.remove(id);
      enqueueSnackbar("Remoção realizada com sucesso", { variant: "success" });
      getData();
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <DataGrid
      rows={data}
      columns={columns}
      editTo={(id) => `form/${id}`}
      onClickDelete={onClickDelete}
      loading={loadingData}
    />
  );
};

export default RestricaoView;
