import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import DataGrid from "../../../components/dataGrid";
import { useDispatch } from "react-redux";
import { endLoading, startLoading } from "../../../store/loadingSlice";
import { getErrorMessage } from "../../../utils";
import newsService from "../../../services/news";

const NewsView = () => {
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loadingData, setLoadingData] = useState(false);
  const dispatch = useDispatch();
  const columns = [
    {
      field: "title",
      headerName: "Título",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "publish",
      headerName: "Publicado",
      minWidth: 150,
      type: "boolean",
    },
  ];

  const getData = useCallback(async () => {
    try {
      setLoadingData(true);
      const { data } = await newsService.getAll();
      setData(data);
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      setLoadingData(false);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onClickDelete = async (id) => {
    try {
      dispatch(startLoading());
      await newsService.remove(id);
      enqueueSnackbar("Remoção realizada com sucesso", {
        variant: "success",
      });
      getData();
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <DataGrid
      rows={data}
      columns={columns}
      editTo={(id) => `form/${id}`}
      onClickDelete={onClickDelete}
      loading={loadingData}
    />
  );
};

export default NewsView;
