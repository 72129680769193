import api from "../common/api";

const getAll = () => api.get("universidade/integracao/financeiro");

const get = (id) => api.get(`universidade/integracao/financeiro/${id}`);

const create = () => api.post("universidade/integracao/financeiro");

const update = (id, data) =>
  api.put(`universidade/integracao/financeiro/${id}`, data);

const addVideo = (id, data) =>
  api.put(`universidade/integracao/financeiro/${id}/video`, data);

const addArquivo = (id, data) =>
  api.put(`universidade/integracao/financeiro/${id}/arquivo`, data);

const remove = (id) => api.delete(`universidade/integracao/financeiro/${id}`);

const removeVideo = (id, idVideo) =>
  api.delete(`universidade/integracao/financeiro/${id}/video/${idVideo}`);

const removeArquivo = (id, idArquivo) =>
  api.delete(`universidade/integracao/financeiro/${id}/arquivo/${idArquivo}`);

const universidadeIntegracaoFinanceiroService = {
  getAll,
  get,
  create,
  update,
  addVideo,
  addArquivo,
  remove,
  removeVideo,
  removeArquivo,
};

export default universidadeIntegracaoFinanceiroService;
