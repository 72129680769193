import axios from "axios";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_URL_API}/system`,
});

api.interceptors.request.use((config) => {
  const token = sessionStorage.getItem("vl_token");

  if (!token) return config;

  const newConfig = { ...config };
  newConfig.headers.Authorization = `Bearer ${token}`;
  return newConfig;
});

export default api;
