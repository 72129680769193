import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import DataGrid from "../../components/dataGrid";
import { useDispatch } from "react-redux";
import { endLoading, startLoading } from "../../store/loadingSlice";
import { getErrorMessage, moneyFormatter } from "../../utils";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import productService from "../../services/product";

const ProductView = () => {
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loadingData, setLoadingData] = useState(false);
  const dispatch = useDispatch();
  const [page, setPage] = useState(sessionStorage.getItem("vl_page") ?? 0);
  const [openDialog, setOpenDialog] = useState(false);
  const columns = [
    {
      field: "name",
      headerName: "Produto",
      flex: 4,
      minWidth: 150,
      renderCell: (params) => {
        if (params.row.photos.length) {
          return (
            <Box display="flex" alignItems="center" width="100%">
              <Avatar
                alt={params.row.name}
                src={`${process.env.REACT_APP_URL_API}/image/${params.row.photos[0].filename}`}
                sx={{ width: 56, height: 56 }}
              />
              <Typography variant="body2" pl={1.5}>
                {params.row.name}
              </Typography>
            </Box>
          );
        } else {
          return (
            <Box display="flex" alignItems="center" width="100%">
              <Avatar sx={{ width: 56, height: 56 }}>
                {params.row.name[0].toUpperCase()}
              </Avatar>
              <Typography variant="body2" pl={1.5}>
                {params.row.name}
              </Typography>
            </Box>
          );
        }
      },
    },
    {
      field: "ref",
      headerName: "Referência",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "amount",
      headerName: "Preço",
      flex: 1,
      minWidth: 150,
      type: "number",
      valueFormatter: ({ value }) => moneyFormatter(value),
    },
    {
      field: "stock",
      headerName: "Estoque",
      flex: 1,
      minWidth: 150,
      type: "number",
    },
    {
      field: "active",
      headerName: "Ativo",
      type: "boolean",
    },
    {
      field: "recommended",
      headerName: "Recomendado",
      type: "boolean",
    },
  ];

  const getData = useCallback(async () => {
    try {
      setLoadingData(true);
      const { data } = await productService.getAll();
      setData(data);
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      setLoadingData(false);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onClickDelete = async (id) => {
    try {
      dispatch(startLoading());
      await productService.remove(id);
      enqueueSnackbar("Remoção realizada com sucesso", {
        variant: "success",
      });
      getData();
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  const onImport = async () => {
    const file = document.getElementById("excelFile").files[0];

    if (!file) {
      return enqueueSnackbar("Selecione um arquivo para importar", {
        variant: "warning",
      });
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      dispatch(startLoading());
      const { data } = await productService.importAll(formData);
      setOpenDialog(false);
      enqueueSnackbar(data.msg, { variant: "success" });
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
      getData();
    }
  };

  return (
    <>
      <DataGrid
        rows={data}
        columns={columns}
        editTo={(id) => `form/${id}`}
        onClickDelete={onClickDelete}
        loading={loadingData}
        rowHeight={60}
        initialPage={page}
        onPageChange={(newPage) => {
          setPage(newPage);
          sessionStorage.setItem("vl_page", newPage);
        }}
        exportLink={`${
          process.env.REACT_APP_URL_API
        }/download/product/${sessionStorage.getItem("vl_token")}/0`}
        onImport={() => setOpenDialog(true)}
      />

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Importar produtos de um arquivo Excel</DialogTitle>
        <DialogContent>
          <Box mt={1}>
            <input
              type="file"
              style={{ width: "100%" }}
              multiple={false}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              id="excelFile"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancelar</Button>
          <Button onClick={onImport}>Importar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProductView;
