import api from "../common/api";

const getAll = () => api.get("/brand");

const get = (id) => api.get(`/brand/${id}`);

const create = (data) => api.post("/brand", data);

const update = (data, id) => api.put(`/brand/${id}`, data);

const remove = (id) => api.delete(`/brand/${id}`);

const brandService = { getAll, get, create, update, remove };

export default brandService;
