const systemUserColumns = [
  {
    field: "name",
    headerName: "Nome",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "email",
    headerName: "E-mail",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "role",
    headerName: "Grupo de Permissão",
    flex: 1,
    minWidth: 150,
    valueGetter: (params) => params.row?.role?.name,
  },
];

export default systemUserColumns;
