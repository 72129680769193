import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectLoading } from "../store/loadingSlice";

const LoadingBackdrop = () => {
  const isLoading = useSelector(selectLoading);
  const isLoadingUser = useSelector((state) => state.auth.isLoadingUser);

  return (
    <Backdrop open={isLoading || isLoadingUser} sx={{ zIndex: 2000 }}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        color="white"
      >
        <CircularProgress color="inherit" />
        <Box pl={2}>
          <Typography variant="body1">Aguarde...</Typography>
        </Box>
      </Box>
    </Backdrop>
  );
};

export default LoadingBackdrop;
