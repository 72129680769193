import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import yup from "../../common/validator";
import { useDispatch } from "react-redux";
import { endLoading, startLoading } from "../../store/loadingSlice";
import { getErrorMessage } from "../../utils";
import brandService from "../../services/brand";
import companyService from "../../services/company";
import UploadImage from "../../components/uploadImage";
import { useState } from "react";

const validation = {
  file: yup.mixed().required(),
  name: yup.string().trim().required(),
  companies: yup.array(),
};

const BrandFormView = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { control, handleSubmit, reset, watch, setValue } = useForm({
    resolver: yupResolver(yup.object().shape(validation)),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const file = watch("file");
  const [company, setCompany] = useState([]);

  const getData = useCallback(async () => {
    try {
      dispatch(startLoading());
      if (id) {
        const [brand, company] = await Promise.all([
          brandService.get(id),
          companyService.getAll(),
        ]);
        reset({ file: { filename: brand.data.filename }, ...brand.data });
        setCompany(company.data);
      } else {
        const { data } = await companyService.getAll();
        setCompany(data);
      }
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
      navigate("..", { replace: true });
    } finally {
      dispatch(endLoading());
    }
  }, [dispatch, enqueueSnackbar, id, navigate, reset]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onSubmit = async (data) => {
    try {
      dispatch(startLoading());
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        if (key === "file") {
          if (!data[key].id) {
            if (data[key].fileUrl) {
              URL.revokeObjectURL(data[key].fileUrl);
            }
            formData.append(key, data[key]);
          }
        } else if (typeof data[key] === "object" && data[key] !== null) {
          formData.append(key, JSON.stringify(data[key]));
        } else {
          formData.append(key, data[key]);
        }
      });

      id
        ? await brandService.update(formData, id)
        : await brandService.create(formData);
      enqueueSnackbar(`${id ? "Edição" : "Cadastro"} realizado com sucesso`, {
        variant: "success",
      });
      navigate("..");
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <UploadImage
                control={control}
                name="file"
                file={file}
                setValue={setValue}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="name"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label="Nome"
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="companies"
                defaultValue={[]}
                render={({
                  field: { onChange, ...rest },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    {...rest}
                    options={company}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    fullWidth
                    multiple
                    onChange={(event, value) => onChange(value)}
                    renderInput={(params) => (
                      <TextField
                        label="Empresas"
                        error={!!error}
                        helperText={!!error && "O campo é obrigatório."}
                        {...params}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button component={Link} to=".." color="error">
            Cancelar
          </Button>
          <Button type="submit">Salvar</Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default BrandFormView;
