const estados = [
  { id: "AC", label: "Acre (AC)" },
  { id: "AL", label: "Alagoas (AL)" },
  { id: "AM", label: "Amazonas (AM)" },
  { id: "AP", label: "Amapá (AP)" },
  { id: "BA", label: "Bahia (BA)" },
  { id: "CE", label: "Ceará (CE)" },
  { id: "DF", label: "Distrito Federal (DF)" },
  { id: "ES", label: "Espírito Santo (ES)" },
  { id: "GO", label: "Goiás (GO)" },
  { id: "MA", label: "Maranhão (MA)" },
  { id: "MT", label: "Mato Grosso (MT)" },
  { id: "MS", label: "Mato Grosso do Sul (MS)" },
  { id: "MG", label: "Minas Gerais (MG)" },
  { id: "PA", label: "Pará (PA)" },
  { id: "PB", label: "Paraíba (PB)" },
  { id: "PR", label: "Paraná (PR)" },
  { id: "PE", label: "Pernambuco (PE)" },
  { id: "PI", label: "Piauí (PI)" },
  { id: "RJ", label: "Rio de Janeiro (RJ)" },
  { id: "RN", label: "Rio Grande do Norte (RN)" },
  { id: "RS", label: "Rio Grande do Sul (RS)" },
  { id: "RO", label: "Rondônia (RO)" },
  { id: "RR", label: "Roraima (RR)" },
  { id: "SC", label: "Santa Catarina (SC)" },
  { id: "SP", label: "São Paulo (SP)" },
  { id: "SE", label: "Sergipe (SE)" },
  { id: "TO", label: "Tocantins (TO)" },
];
export default estados;
