import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import DataGrid from "../../components/dataGrid";
import { getErrorMessage, moneyFormatter } from "../../utils";
import moment from "moment";
import orderService from "../../services/order";
import HeaderView from "../../components/headerView";
import { Container } from "@mui/material";

const OrderView = () => {
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loadingData, setLoadingData] = useState(false);
  const columns = [
    {
      field: "dateCreated",
      headerName: "Data e Hora",
      minWidth: 150,
      flex: 1,
      type: "dateTime",
      valueFormatter: ({ value }) =>
        moment(value).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      field: "user",
      headerName: "Cliente",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) =>
        params.row?.user.isPJ
          ? params.row.user.corporateName_firstName
          : `${params.row.user.corporateName_firstName} ${params.row.user.fantasyName_lastName}`,
    },
    {
      field: "amount",
      headerName: "Valor",
      flex: 1,
      minWidth: 150,
      type: "number",
      valueFormatter: ({ value }) => moneyFormatter(value),
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      flex: 1,
      type: "singleSelect",
      valueOptions: [
        "Aguardando confirmação do pagamento",
        "Pagamento confirmado",
        "Pedido em separação",
        "Pedido em rota de entrega",
        "Pedido entregue",
        "Aguardando cancelamento do pedido",
        "Pedido cancelado",
      ],
    },
  ];

  const getData = useCallback(async () => {
    try {
      setLoadingData(true);
      const { data } = await orderService.getAll();
      setData(data);
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      setLoadingData(false);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Container maxWidth="lg">
      <HeaderView title="Pedidos" />

      <DataGrid
        rows={data}
        columns={columns}
        loading={loadingData}
        viewTo={(idSelect) => `/order/view/${idSelect}`}
      />
    </Container>
  );
};

export default OrderView;
