import api from "../common/api";

const getNodemailer = () => api.get("/settings/nodemailer");

const updateNodemailer = (data) => api.put("/settings/nodemailer", data);

const getRede = () => api.get("/settings/rede");

const updateRede = (data) => api.put("/settings/rede", data);

const settingsService = {
  getNodemailer,
  updateNodemailer,
  getRede,
  updateRede,
};

export default settingsService;
