import { DeleteForeverOutlined } from "@mui/icons-material";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DataGrid from "../../components/dataGrid";
import promotionService from "../../services/promotion";
import { endLoading, startLoading } from "../../store/loadingSlice";
import { getErrorMessage, moneyFormatter } from "../../utils";

const PromotionView = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const columns = [
    {
      field: "name",
      headerName: "Produto",
      flex: 4,
      minWidth: 150,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" width="100%">
          <Avatar
            alt={params.row.name}
            src={`${process.env.REACT_APP_URL_API}/image/${params.row.photos[0].filename}`}
            sx={{ width: 56, height: 56 }}
          />
          <Typography variant="body2" pl={1.5}>
            {params.row.name}
          </Typography>
        </Box>
      ),
    },
    {
      field: "ref",
      headerName: "Referência",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "amount",
      headerName: "Preço",
      flex: 1,
      minWidth: 150,
      type: "number",
      valueFormatter: ({ value }) => moneyFormatter(value),
    },
    {
      field: "amountPromotion",
      headerName: "Preço Promocional",
      flex: 1,
      minWidth: 150,
      type: "number",
      valueFormatter: ({ value }) => moneyFormatter(value),
    },
    {
      field: "action",
      headerName: "Ações",
      headerAlign: "center",
      width: 80,
      align: "center",
      renderCell: (params) => (
        <IconButton
          color="error"
          mx={2}
          onClick={async () => {
            try {
              dispatch(startLoading());
              await promotionService.remove(params.row.id);
            } catch (err) {
              enqueueSnackbar(getErrorMessage(err), { variant: "error" });
            } finally {
              getData();
              dispatch(endLoading());
            }
          }}
        >
          <DeleteForeverOutlined />
        </IconButton>
      ),
    },
  ];

  const getData = useCallback(async () => {
    try {
      setLoadingData(true);
      const { data } = await promotionService.getAll();
      setData(data);
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      setLoadingData(false);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <DataGrid
      rows={data}
      columns={columns}
      loading={loadingData}
      rowHeight={60}
    />
  );
};

export default PromotionView;
