import { Divider, List } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/authSlice";
import SidebarItem from "./sidebarItem";
import SidebarMenu from "./sidebarMenu";

const SidebarGroup = ({ group, isLast }) => {
  const [groups, setGroups] = useState([]);
  const user = useSelector(selectUser);

  useEffect(() => {
    if (group.children) {
      setGroups(
        group.children.flatMap((item) => {
          if (user) {
            if (item.permission) {
              if (
                user.role.permissions.some((i) => i.name === item.permission)
              ) {
                if (item.type === "item")
                  return (
                    <SidebarItem
                      key={`${item.type}-${item.title}`}
                      item={item}
                      level={1}
                    />
                  );
                if (item.type === "menu")
                  return (
                    <SidebarMenu
                      key={`${item.type}-${item.title}`}
                      menu={item}
                      level={1}
                    />
                  );
              }
            }
            if (!item.permission) {
              if (item.type === "item")
                return (
                  <SidebarItem
                    key={`${item.type}-${item.title}`}
                    item={item}
                    level={1}
                  />
                );
              if (item.type === "menu")
                return (
                  <SidebarMenu
                    key={`${item.type}-${item.title}`}
                    menu={item}
                    level={1}
                  />
                );
            }
          }
          return [];
        })
      );
    }
  }, [group, user]);

  return (
    <>
      {groups.length > 0 && <List>{groups}</List>}

      {!isLast && groups.length > 0 && <Divider sx={{ marginBottom: 5 }} />}
    </>
  );
};

export default SidebarGroup;
