import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { getErrorMessage, moneyFormatter } from "../../utils";
import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import api from "../../common/api";
import logo from "../../assets/images/vetline.png";

const SalesView = () => {
  const [data, setData] = useState({
    total: "0",
    totalApp: "0",
    totalEcommerce: "0",
    totalProductSales: 0,
    totalProductCancel: 0,
    totalProductPending: 0,
    userSales: [],
  });
  const { enqueueSnackbar } = useSnackbar();
  const [loadingData, setLoadingData] = useState(false);
  const dateNow = new Date();
  const [valueDate, setValueDate] = useState({
    start: moment(new Date(dateNow.getFullYear(), dateNow.getMonth(), 1)),
    end: moment(),
  });
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [anchorElStartDate, setAnchorElStartDate] = useState(null);
  const [anchorElEndDate, setAnchorElEndDate] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [openDateStart, setOpenDateStart] = useState(false);
  const [openDateEnd, setOpenDateEnd] = useState(false);

  const getData = useCallback(async () => {
    try {
      setLoadingData(true);
      const { data } = await api.get(
        `/report/sales/${valueDate.start.toISOString()}/${valueDate.end.toISOString()}`
      );
      setData(data);
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      setLoadingData(false);
    }
  }, [enqueueSnackbar, valueDate.end, valueDate.start]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <Container maxWidth="lg" sx={{ displayPrint: "none" }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          marginBottom={4}
        >
          <Grid item>
            <Grid item container direction="column" spacing={1}>
              <Grid item>
                <Typography variant="h5" component="h1" lineHeight={1.55}>
                  Resultados de Vendas
                </Typography>
              </Grid>
              <Grid item>
                <Box display="flex">
                  <Typography>de</Typography>
                  <Box
                    onClick={(event) => {
                      event.preventDefault();
                      setAnchorElStartDate(event.currentTarget);
                      setOpenDateStart(true);
                    }}
                    sx={{ userSelect: "none", px: 0.5 }}
                  >
                    <Typography
                      fontWeight={900}
                      sx={(theme) => ({
                        color: theme.palette.primary.main,
                        cursor: "pointer",
                      })}
                    >
                      {valueDate.start.format("DD/MM/YYYY")}
                    </Typography>
                  </Box>

                  <Typography>até</Typography>
                  <Box
                    onClick={(event) => {
                      event.preventDefault();
                      setAnchorElEndDate(event.currentTarget);
                      setOpenDateEnd(true);
                    }}
                    sx={{ userSelect: "none", pl: 0.5 }}
                  >
                    <Typography
                      fontWeight={900}
                      sx={(theme) => ({
                        color: theme.palette.primary.main,
                        cursor: "pointer",
                      })}
                    >
                      {valueDate.end.format("DD/MM/YYYY")}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              endIcon={<KeyboardArrowDownIcon />}
              onClick={(event) => {
                setAnchorElMenu(event.currentTarget);
                setOpenMenu(true);
              }}
              sx={(theme) => ({
                [theme.breakpoints.down("xs")]: {
                  padding: "4px 10px",
                  fontSize: "0.8125rem",
                },
              })}
            >
              Baixar
            </Button>
          </Grid>
        </Grid>

        <Box
          my={1.5}
          display="flex"
          gap={2}
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
          maxWidth={670}
          mx="auto"
        >
          <Card elevation={5} sx={{ width: 210, height: 150 }}>
            <CardContent sx={{ height: "100%" }}>
              <Box
                display="flex"
                height="100%"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                gap={2}
              >
                <Typography textAlign="center" pb={3}>
                  Total de Vendas
                </Typography>
                <Divider flexItem />
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  {loadingData ? (
                    <CircularProgress size={25} />
                  ) : (
                    <>
                      <Typography fontSize="large" fontWeight="bold">
                        {moneyFormatter(data.total)}
                      </Typography>
                    </>
                  )}
                </Stack>
              </Box>
            </CardContent>
          </Card>
          <Card elevation={5} sx={{ width: 210, height: 150 }}>
            <CardContent sx={{ height: "100%" }}>
              <Box
                display="flex"
                height="100%"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                gap={2}
              >
                <Typography textAlign="center" px={2}>
                  Total de Vendas no App
                </Typography>
                <Divider flexItem />
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  {loadingData ? (
                    <CircularProgress size={25} />
                  ) : (
                    <>
                      <Typography fontWeight="bold" fontSize="large">
                        {moneyFormatter(data.totalApp)}
                      </Typography>
                    </>
                  )}
                </Stack>
              </Box>
            </CardContent>
          </Card>
          <Card elevation={5} sx={{ width: 210, height: 150 }}>
            <CardContent sx={{ height: "100%" }}>
              <Box
                display="flex"
                height="100%"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                gap={2}
              >
                <Typography textAlign="center" px={2}>
                  Total de Vendas no E-commerce
                </Typography>
                <Divider flexItem />
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  {loadingData ? (
                    <CircularProgress size={25} />
                  ) : (
                    <>
                      <Typography fontWeight="bold" fontSize="large">
                        {moneyFormatter(data.totalEcommerce)}
                      </Typography>
                    </>
                  )}
                </Stack>
              </Box>
            </CardContent>
          </Card>
          <Card
            elevation={5}
            sx={{ width: 210, height: 110, backgroundColor: "#a0cd51" }}
          >
            <CardContent sx={{ height: "100%" }}>
              <Box
                display="flex"
                height="100%"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                gap={1}
              >
                <Typography textAlign="center">
                  Total de Produtos Vendidos
                </Typography>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  {loadingData ? (
                    <CircularProgress size={25} />
                  ) : (
                    <>
                      <Typography fontWeight="bold" fontSize="large">
                        {data.totalProductSales ? data.totalProductSales : 0}
                      </Typography>
                    </>
                  )}
                </Stack>
              </Box>
            </CardContent>
          </Card>
          <Card
            elevation={5}
            sx={{ width: 210, height: 110, backgroundColor: "#a0cd51" }}
          >
            <CardContent sx={{ height: "100%" }}>
              <Box
                display="flex"
                height="100%"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                gap={1}
              >
                <Typography textAlign="center">
                  Total de Produtos Cancelados
                </Typography>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  {loadingData ? (
                    <CircularProgress size={25} />
                  ) : (
                    <>
                      <Typography fontWeight="bold" fontSize="large">
                        {data.totalProductCancel ? data.totalProductCancel : 0}
                      </Typography>
                    </>
                  )}
                </Stack>
              </Box>
            </CardContent>
          </Card>
          <Card
            elevation={5}
            sx={{ width: 210, height: 110, backgroundColor: "#a0cd51" }}
          >
            <CardContent sx={{ height: "100%" }}>
              <Box
                display="flex"
                height="100%"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                gap={1}
              >
                <Typography textAlign="center">
                  Total de Produtos Pedentes
                </Typography>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  {loadingData ? (
                    <CircularProgress size={25} />
                  ) : (
                    <>
                      <Typography fontWeight="bold" fontSize="large">
                        {data.totalProductPending
                          ? data.totalProductPending
                          : 0}
                      </Typography>
                    </>
                  )}
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Box>

        <Typography variant="h6" component="h2" mt={4} mb={2}>
          Detalhes - Vendas por Clientes
        </Typography>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Cliente</TableCell>
                <TableCell align="right">Qtd. Produto</TableCell>
                <TableCell align="right">Valor (R$)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loadingData ? (
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    <CircularProgress size={25} />
                  </TableCell>
                </TableRow>
              ) : data.userSales.length > 0 ? (
                data.userSales.map((row) => (
                  <TableRow key={Math.random()}>
                    <TableCell component="th" scope="row">
                      {row.isPJ
                        ? row.lastName
                        : `${row.firstName} ${row.lastName}`}
                    </TableCell>
                    <TableCell align="right">{row.totalProduct}</TableCell>
                    <TableCell align="right">
                      {moneyFormatter(row.totalAmount)}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    Nenhum resultado encontrado.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>

      <Menu
        anchorEl={anchorElMenu}
        open={openMenu}
        onClose={() => setOpenMenu(false)}
        sx={{ displayPrint: "none" }}
      >
        <MenuItem
          onClick={() => {
            setOpenMenu(false);
            window.print();
          }}
        >
          PDF
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenMenu(false);
            window.open(
              `${
                process.env.REACT_APP_URL_API
              }/download/sales/${valueDate.start.toISOString()}/${valueDate.end.toISOString()}/${sessionStorage.getItem(
                "vl_token"
              )}/0`,
              "_blank"
            );
          }}
        >
          Excel
        </MenuItem>
      </Menu>

      <LocalizationProvider
        dateAdapter={AdapterMoment}
        sx={{ displayPrint: "none" }}
      >
        <DatePicker
          renderInput={() => null}
          open={openDateStart}
          onClose={() => setOpenDateStart(false)}
          value={valueDate.start}
          onChange={(newValue) =>
            setValueDate((oldValue) => ({ ...oldValue, start: newValue }))
          }
          PopperProps={{ anchorEl: anchorElStartDate }}
          views={["year", "month", "day"]}
          maxDate={valueDate.end}
        />
        <DatePicker
          renderInput={() => null}
          open={openDateEnd}
          onClose={() => setOpenDateEnd(false)}
          value={valueDate.end}
          onChange={(newValue) =>
            setValueDate((oldValue) => ({ ...oldValue, end: newValue }))
          }
          PopperProps={{ anchorEl: anchorElEndDate }}
          views={["year", "month", "day"]}
          minDate={valueDate.start}
        />
      </LocalizationProvider>

      <Container
        disableGutters
        sx={{ display: "none", displayPrint: "initial" }}
      >
        <AppBar
          position="fixed"
          elevation={0}
          sx={{
            backgroundColor: "background.paper",
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            display: "none",
            displayPrint: "initial",
          }}
        >
          <Toolbar sx={{ height: (theme) => theme.headerHeight }}>
            <img src={logo} alt="Logo da Vetline" height={35} />

            <Box flexGrow={1} />

            <Typography variant="body1" color="black" ml={1}>
              Resultados de Vendas (de {valueDate.start.format("DD/MM/YYYY")}{" "}
              até {valueDate.end.format("DD/MM/YYYY")})
            </Typography>
          </Toolbar>
        </AppBar>

        <Grid container spacing={2} direction="column">
          <Grid item>
            <Card>
              <CardContent style={{ paddingBottom: 16 }}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography fontWeight={500}>
                      Vendas:{" "}
                      <span style={{ fontWeight: 400 }}>
                        {moneyFormatter(data.total)}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontWeight={500}>
                      Produtos Vendidos:{" "}
                      <span style={{ fontWeight: 400 }}>
                        {data.totalProductSales ?? 0}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontWeight={500}>
                      Vendas no App:{" "}
                      <span style={{ fontWeight: 400 }}>
                        {moneyFormatter(data.totalApp)}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontWeight={500}>
                      Produtos Cancelados:{" "}
                      <span style={{ fontWeight: 400 }}>
                        {data.totalProductCancel ?? 0}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontWeight={500}>
                      Vendas no E-commerce:{" "}
                      <span style={{ fontWeight: 400 }}>
                        {moneyFormatter(data.totalEcommerce)}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontWeight={500}>
                      Produtos Pedentes:{" "}
                      <span style={{ fontWeight: 400 }}>
                        {data.totalProductPending ?? 0}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item>
            <Typography variant="h6" component="h2" mt={4} mb={2}>
              Vendas por Clientes
            </Typography>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableRow>
                  <TableCell sx={{ fontWeight: 500 }}>Cliente</TableCell>
                  <TableCell sx={{ fontWeight: 500 }} align="right">
                    Qtd. Produto
                  </TableCell>
                  <TableCell sx={{ fontWeight: 500 }} align="right">
                    Valor (R$)
                  </TableCell>
                </TableRow>
                <TableBody>
                  {data.userSales.map((row) => (
                    <TableRow key={Math.random()}>
                      <TableCell>
                        {row.isPJ
                          ? row.lastName
                          : `${row.firstName} ${row.lastName}`}
                      </TableCell>
                      <TableCell align="right">{row.totalProduct}</TableCell>
                      <TableCell align="right">
                        {moneyFormatter(row.totalAmount)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default SalesView;
