import { Card } from "@mui/material";

const TabPanel = ({ children, value, index }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
  >
    {value === index && (
      <Card sx={{ borderRadius: "0 0 4px 4px" }}>{children}</Card>
    )}
  </div>
);

export default TabPanel;
