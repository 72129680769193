import api from "../common/api";

const getAll = () => api.get("contacts");

const get = (id) => api.get(`contacts/${id}`);

const create = (data) => api.post("contacts", data);

const update = (data) => api.put(`contacts/${data.id}`, data);

const remove = (id) => api.delete(`contacts/${id}`);

const contactsService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default contactsService;
