import axios from "axios";

export const getErrorMessage = (err) => {
  if (axios.isAxiosError(err)) {
    if (err.response) return String(err.response.data);
    if (err.request) return String(err.request);
    return err.message;
  } else {
    return "Erro inesperado";
  }
};

export const moneyFormatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}).format;

export const moneyFormatter2 = new Intl.NumberFormat("pt-BR", {
  currency: "BRL",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}).format;

export const percentFormatter = new Intl.NumberFormat("pt-BR", {
  style: "percent",
  maximumFractionDigits: 2,
}).format;

export const phoneFormatter = (value) => {
  const newValue = value
    .replace(/\D/g, "")
    .match(/(\d{0,2})(\d{0,5})(\d{0,4})/);

  if (newValue) {
    return !newValue[2]
      ? newValue[1]
      : `(${newValue[1]}) ${newValue[2]}${
          newValue[3] ? `-${newValue[3]}` : ""
        }`;
  }
  return "";
};

export const cepFormatter = (value) => {
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{5})(\d)/, "$1-$2");
  return value;
};

export const cpfFormatter = (value) => {
  value = value.replace(/(\d{3})(\d)/, "$1.$2");
  value = value.replace(/(\d{3})(\d)/, "$1.$2");
  value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

  return value;
};

export const cnpjFormatter = (value) => {
  value = value.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    "$1.$2.$3/$4-$5"
  );
  return value;
};

export const rgFormatter = (value) => {
  value = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{1})/, "$1.$2.$3-$4");
  return value;
};

export function youtubeParser(url) {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
}
