import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import DataGrid from "../../components/dataGrid";
import { useDispatch } from "react-redux";
import { endLoading, startLoading } from "../../store/loadingSlice";
import { getErrorMessage } from "../../utils";
import brandService from "../../services/brand";
import { Avatar, Box, IconButton, Tooltip, Typography } from "@mui/material";
import { ShoppingCartOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";

const BrandView = () => {
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loadingData, setLoadingData] = useState(false);
  const dispatch = useDispatch();
  const columns = [
    {
      field: "name",
      headerName: "Fabricante",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" width="100%">
          <Avatar
            alt={params.row.name}
            src={`${process.env.REACT_APP_URL_API}/image/${params.row.filename}`}
            sx={{ width: 56, height: 56 }}
          />
          <Typography variant="body2" pl={1.5}>
            {params.row.name}
          </Typography>

          <Box sx={{ flexGrow: 1 }} />

          <Tooltip title="Restrição de Vendas">
            <IconButton
              component={Link}
              to={`${params.row.id}/restricao-venda`}
            >
              <ShoppingCartOutlined />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const getData = useCallback(async () => {
    try {
      setLoadingData(true);
      const { data } = await brandService.getAll();
      setData(data);
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      setLoadingData(false);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onClickDelete = async (id) => {
    try {
      dispatch(startLoading());
      await brandService.remove(id);
      enqueueSnackbar("Remoção realizada com sucesso", { variant: "success" });
      getData();
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <DataGrid
      rows={data}
      columns={columns}
      editTo={(id) => `form/${id}`}
      onClickDelete={onClickDelete}
      loading={loadingData}
      rowHeight={60}
    />
  );
};

export default BrandView;
