import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../services/auth";
import { getErrorMessage } from "../utils";

const initialState = {
  user: null,
  isLoadingUser: false,
};

export const checkUser = createAsyncThunk(
  "checkUser",
  async (token, { rejectWithValue }) => {
    if (token) {
      try {
        const { data } = await authService.validateToken(token);
        return data;
      } catch (err) {
        sessionStorage.removeItem("vl_token");
        return rejectWithValue(getErrorMessage(err));
      }
    }
    return rejectWithValue("Token não encontrado");
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signIn: (state, action) => {
      state.user = action.payload.user;
      if (action.payload.token) {
        sessionStorage.setItem("vl_token", action.payload.token);
      }
    },
    signOut: (state) => {
      state.user = null;
      sessionStorage.removeItem("vl_token");
    },
  },
  extraReducers: (build) => {
    build.addCase(checkUser.pending, (state) => {
      state.isLoadingUser = true;
    });
    build.addCase(checkUser.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isLoadingUser = false;
    });
    build.addCase(checkUser.rejected, (state) => {
      state.user = null;
      state.isLoadingUser = false;
    });
  },
});

export const { signIn, signOut } = authSlice.actions;

export const selectUser = (state) => state.auth.user;

export default authSlice.reducer;
