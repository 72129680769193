import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import yup from "../../common/validator";
import { useDispatch } from "react-redux";
import permissionService from "../../services/permission";
import systemUserService from "../../services/systemUser";
import { endLoading, startLoading } from "../../store/loadingSlice";
import { getErrorMessage } from "../../utils";

const validation = {
  name: yup.string().trim().required(),
  email: yup.string().trim().required(),
  role: yup
    .object()
    .shape({ id: yup.number().required(), name: yup.string().required() }),
};

const SystemUserFormView = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [rolePermissions, setRolePermissions] = useState([]);
  const [schema, setSchema] = useState(yup.object().shape(validation));
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getData = useCallback(async () => {
    try {
      dispatch(startLoading());
      if (id) {
        const [user, roles] = await Promise.all([
          systemUserService.get(+id),
          permissionService.getAllRole(),
        ]);
        reset(user.data);
        setRolePermissions(roles.data);
      } else {
        const { data } = await permissionService.getAllRole();
        setRolePermissions(data);
      }
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
      navigate("..", { replace: true });
    } finally {
      dispatch(endLoading());
    }
  }, [dispatch, enqueueSnackbar, id, navigate, reset]);

  useEffect(() => {
    if (id) {
      setSchema(yup.object().shape(validation));
    } else {
      setSchema(
        yup
          .object()
          .shape({ password: yup.string().trim().required(), ...validation })
      );
    }
  }, [id]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onSubmit = async (data) => {
    try {
      dispatch(startLoading());
      if (id) {
        await systemUserService.update(data);
      } else {
        await systemUserService.create(data);
      }
      enqueueSnackbar(`${id ? "Edição" : "Cadastro"} realizado com sucesso`, {
        variant: "success",
      });
      navigate("..");
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="name"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label="Nome"
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="email"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label="E-mail"
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="password"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label="Senha"
                    type="password"
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="role"
                defaultValue={null}
                render={({
                  field: { onChange, ...rest },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    {...rest}
                    options={rolePermissions}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    fullWidth
                    onChange={(event, value) => onChange(value)}
                    renderInput={(params) => (
                      <TextField
                        label="Grupo de Permissões"
                        error={!!error}
                        helperText={!!error && "O campo é obrigatório."}
                        {...params}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button component={Link} to=".." color="error">
            Cancelar
          </Button>
          <Button type="submit">Salvar</Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default SystemUserFormView;
