import { ReactComponent as PhonebookIcon } from "../assets/phonebook.svg";
import { ReactComponent as UniversidadeIcon } from "../assets/universidade.svg";
import { ReactComponent as PromotionIcon } from "../assets/promotion.svg";

const sidebarItems = [
  {
    title: "Grupo Principal",
    type: "group",
    children: [
      {
        title: "Dashboard",
        type: "item",
        icon: "DashboardOutlined",
        url: "/",
      },
      {
        title: "Pedidos",
        type: "item",
        icon: "ShoppingCartOutlined",
        url: "order",
        permission: "Pedidos",
      },
      {
        title: "Produtos",
        type: "menu",
        icon: "Inventory2Outlined",
        permission: "Produtos",
        children: [
          {
            title: "Gerenciamento de Produtos",
            type: "item",
            url: "product",
          },
          {
            title: "Gerenciamento de Estoque",
            type: "item",
            url: "stock",
          },
        ],
      },
      {
        title: "Descontos",
        type: "menu",
        icon: "SellOutlined",
        permission: "Descontos",
        children: [
          {
            title: "Cupons",
            type: "item",
            url: "coupon",
          },
          {
            title: "Promoções",
            type: "item",
            url: "promotion",
          },
        ],
      },
      {
        title: "Financeiro",
        type: "menu",
        icon: "PaymentsOutlined",
        permission: "Financeiro",
        children: [
          {
            title: "Histórico de Pagamento",
            type: "item",
            url: "payment-history",
          },
          {
            title: "Tipo de Venda (Boleto)",
            type: "item",
            url: "sale-bankslip",
          },
        ],
      },
      {
        title: "Clientes",
        type: "menu",
        icon: "PersonOutlined",
        permission: "Clientes",
        children: [
          {
            title: "Cliente (Pessoa Jurídica)",
            type: "item",
            url: "client-pj",
          },
          {
            title: "Cliente (Pessoa Física)",
            type: "item",
            url: "client-pf",
          },
        ],
      },
      {
        title: "Cadastros",
        type: "menu",
        icon: "AddCircleOutlineOutlined",
        permission: "Cadastros",
        children: [
          {
            title: "Empresas",
            type: "item",
            url: "company",
          },
          {
            title: "Fabricantes",
            type: "item",
            url: "brand",
          },
          {
            title: "Categorias",
            type: "item",
            url: "category",
          },
        ],
      },
      {
        title: "Relatórios",
        type: "menu",
        icon: "AnalyticsOutlined",
        permission: "Relatórios",
        children: [
          {
            title: "Listagem de Estoque",
            type: "item",
            url: "stock-listing",
          },
          {
            title: "Histórico de Produtos",
            type: "item",
            url: "product-historic",
          },
        ],
      },
      {
        title: "Comunicações",
        type: "menu",
        iconSvg: PromotionIcon,
        permission: "Comunicações",
        children: [
          {
            title: "Comunicado",
            type: "item",
            url: "comunicado",
          },
          {
            title: "Notícias",
            type: "item",
            url: "news",
          },
        ],
      },
      {
        title: "Universidade",
        type: "menu",
        iconSvg: UniversidadeIcon,
        permission: "Universidade",
        children: [
          {
            title: "Integração",
            type: "menu",
            children: [
              {
                title: "Logística",
                type: "item",
                url: "universidade/integracao/logistica",
              },
              {
                title: "Business Intelligence",
                type: "item",
                url: "universidade/integracao/bi",
              },
              {
                title: "Financeiro",
                type: "item",
                url: "universidade/integracao/financeiro",
              },
              {
                title: "Vendas",
                type: "item",
                url: "universidade/integracao/vendas",
              },
            ],
          },
          {
            title: "Vídeos",
            type: "item",
            url: "universidade/video",
          },
          {
            title: "Artigos e Notícias",
            type: "item",
            url: "universidade/news",
          },
          {
            title: "Cursos EAD Livre",
            type: "item",
            url: "universidade/links",
          },
          {
            title: "Cursos",
            type: "item",
            url: "universidade/cursos",
          },
        ],
      },
      {
        title: "Notificação Push",
        type: "item",
        icon: "NotificationsOutlined",
        permission: "Notificação Push",
        url: "push-notification",
      },
      {
        title: "Agenda Telefônica",
        type: "item",
        iconSvg: PhonebookIcon,
        permission: "Agenda Telefônica",
        url: "contacts",
      },
      {
        title: "Sistema",
        type: "menu",
        icon: "SettingsOutlined",
        permission: "Sistema",
        children: [
          {
            title: "Usuários (Painel Web)",
            type: "item",
            url: "user",
          },
          {
            title: "Slides (App)",
            type: "item",
            url: "slide",
          },
          {
            title: "Configurações Gerais",
            type: "item",
            url: "settings",
          },
        ],
      },
    ],
  },
];

export default sidebarItems;
