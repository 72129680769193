import api from "../common/api";

const getAll = () => api.get("/salebankslip");

const get = (id) => api.get(`/salebankslip/${id}`);

const create = (data) => api.post("/salebankslip", data);

const update = (data) => api.put(`/salebankslip/${data.id}`, data);

const remove = (id) => api.delete(`/salebankslip/${id}`);

const saleBankSlipService = { getAll, get, create, update, remove };

export default saleBankSlipService;
