import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: window.innerWidth >= 960,
};

export const drawerSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    toggleDrawer: (state) => {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { toggleDrawer } = drawerSlice.actions;

export const selectDrawer = (state) => state.drawer.isOpen;

export default drawerSlice.reducer;
