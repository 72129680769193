import { createElement, useEffect, useState } from "react";
import * as MaterialIcon from "@mui/icons-material";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/authSlice";
import SidebarItem from "./sidebarItem";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Typography,
} from "@mui/material";

const SidebarMenu = ({ menu, level }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [menus, setMenus] = useState([]);
  const menuIcon = menu.icon ? createElement(MaterialIcon[menu.icon]) : null;
  const user = useSelector(selectUser);

  const handleCkick = () => {
    setOpen(!open);
    setSelected(!selected ? `${menu.type}-${menu.title}` : null);
  };

  useEffect(() => {
    if (menu.children) {
      setMenus(
        menu.children.flatMap((item) => {
          if (user) {
            if (item.permission) {
              if (
                user.role.permissions.some((i) => i.name === item.permission)
              ) {
                if (item.type === "item")
                  return (
                    <SidebarItem
                      key={`${item.type}-${item.title}`}
                      item={item}
                      level={level + 1}
                    />
                  );
                if (item.type === "menu")
                  return (
                    <SidebarMenu
                      key={`${item.type}-${item.title}`}
                      menu={item}
                      level={level + 1}
                    />
                  );
              }
            }
            if (!item.permission) {
              if (item.type === "item")
                return (
                  <SidebarItem
                    key={`${item.type}-${item.title}`}
                    item={item}
                    level={level + 1}
                  />
                );
              if (item.type === "menu")
                return (
                  <SidebarMenu
                    key={`${item.type}-${item.title}`}
                    menu={item}
                    level={level + 1}
                  />
                );
            }
          }
          return [];
        })
      );
    }
  }, [menu, level, user]);

  return menus?.length > 0 ? (
    <>
      <ListItem
        button
        selected={selected === `${menu.type}-${menu.title}`}
        onClick={handleCkick}
        sx={{
          paddingLeft: `${level * 18}px`,
          marginBottom: "5px",
          alignItems: "flex-start",
          borderRadius: 1,
          paddingY: "8px",
          backgroundColor: "transparent",
          "&:hover": {
            backgroundColor: (theme) =>
              level <= 1 ? theme.palette.action.hover : "transparent",
            color: "primary.main",
            "& svg": {
              color: "primary.main",
            },
          },
          "&.Mui-selected": {
            backgroundColor: level <= 1 ? "" : "transparent",
            color: "primary.main",
            "& svg": {
              color: "primary.main",
            },
          },
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: menu.icon ? 28 : 18,
            marginY: "auto",
          }}
        >
          {menu.iconSvg ? (
            <SvgIcon component={menu.iconSvg} inheritViewBox sx={{ mr: 0.5 }} />
          ) : (
            menuIcon
          )}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="body2" component="p" color="inherit">
              {menu.title}
            </Typography>
          }
        />
        {open ? (
          <MaterialIcon.KeyboardArrowUpRounded sx={{ marginY: "auto" }} />
        ) : (
          <MaterialIcon.KeyboardArrowDownRounded sx={{ marginY: "auto" }} />
        )}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {menus}
        </List>
      </Collapse>
    </>
  ) : (
    <></>
  );
};

export default SidebarMenu;
