import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { selectDrawer } from "../../store/drawerSlice";
import Header from "./header";
import Sidebar from "./sidebar";

const MainLayout = () => {
  const drawer = useSelector(selectDrawer);

  return (
    <Box display="flex">
      <Header />
      <Sidebar />

      <Box
        sx={[
          (theme) => ({
            width: "100%",

            marginTop: `${theme.headerHeight}px`,
            overflowX: "hidden",
            transition: theme.transitions.create("margin", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            [theme.breakpoints.up("md")]: {
              marginLeft: `-${theme.sidebarWidth}px`,
            },
            "@media print": {
              marginLeft: "0px !important",
            },
          }),
          drawer && {
            marginLeft: "0px !important",
            transition: (theme) =>
              theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
              }),
          },
        ]}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
