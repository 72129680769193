import * as yup from "yup";
import pushNotificationService from "../../services/pushNotification";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { endLoading, startLoading } from "../../store/loadingSlice";
import { getErrorMessage } from "../../utils";

const validation = {
  title: yup.string().trim().required(),
  body: yup.string().trim().required(),
};

export default function PushNew() {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(yup.object().shape(validation)),
  });
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    try {
      dispatch(startLoading());
      await pushNotificationService.create(data);
      enqueueSnackbar("Notificão enviada com sucesso", { variant: "success" });
      reset();
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="title"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label="Título"
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="body"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label="Texto"
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button type="submit">Enviar</Button>
        </CardActions>
      </form>
    </Card>
  );
}
