import api from "../common/api";

const getAll = () => api.get("communications/comunicado");

const get = (id) => api.get(`communications/comunicado/${id}`);

const create = () => api.post("communications/comunicado");

const uploadPhoto = (id, data) =>
  api.post(`communications/comunicado/${id}/photo`, data);

const update = (id, data) => api.put(`communications/comunicado/${id}`, data);

const remove = (id) => api.delete(`communications/comunicado/${id}`);

const comunicadoService = {
  getAll,
  get,
  create,
  update,
  remove,
  uploadPhoto,
};

export default comunicadoService;
