import {
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import Blockquote from "@tiptap/extension-blockquote";
import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
import Document from "@tiptap/extension-document";
import Dropcursor from "@tiptap/extension-dropcursor";
import Gapcursor from "@tiptap/extension-gapcursor";
import HardBreak from "@tiptap/extension-hard-break";
import Heading from "@tiptap/extension-heading";
import History from "@tiptap/extension-history";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import Italic from "@tiptap/extension-italic";
import Link from "@tiptap/extension-link";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
import Strike from "@tiptap/extension-strike";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Text from "@tiptap/extension-text";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import {
  FontSize,
  LinkBubbleMenu,
  LinkBubbleMenuHandler,
  MenuButtonAddImage,
  MenuButtonAddTable,
  MenuButtonBlockquote,
  MenuButtonBold,
  MenuButtonBulletedList,
  MenuButtonEditLink,
  MenuButtonHorizontalRule,
  MenuButtonIndent,
  MenuButtonItalic,
  MenuButtonOrderedList,
  MenuButtonRedo,
  MenuButtonRemoveFormatting,
  MenuButtonStrikethrough,
  MenuButtonUnderline,
  MenuButtonUndo,
  MenuButtonUnindent,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectFontSize,
  MenuSelectHeading,
  MenuSelectTextAlign,
  ResizableImage,
  RichTextEditor,
  TableBubbleMenu,
  TableImproved,
  isTouchDevice,
} from "mui-tiptap";
import { useRef } from "react";
import imagesService from "../services/images";
import { useDispatch } from "react-redux";
import { endLoading, startLoading } from "../store/loadingSlice";

const CustomLinkExtension = Link.extend({ inclusive: false });

export default function TextEditor({ value, onChange }) {
  const dispatch = useDispatch();
  const inputFile = useRef(null);
  const rteRef = useRef(null);

  return (
    <Box
      sx={{
        "& .ProseMirror": {
          minHeight: "300px",
          maxHeight: "500px",
          overflowY: "auto",
          "& a": {
            color: "#1976D2 !important",
          },
          "& code": {
            color: "rgba(0, 0, 0, 0.87) !important",
          },
        },
      }}
    >
      <input
        hidden
        accept="image/*"
        type="file"
        ref={inputFile}
        onChange={async (event) => {
          dispatch(startLoading());
          const formData = new FormData();
          formData.append("file", event.target.files[0]);
          const { data } = await imagesService.uploadImage(formData);
          if (data.imageName) {
            rteRef.current?.editor
              ?.chain()
              .focus()
              .setImage({
                src: `${process.env.REACT_APP_URL_API}/image/${data.imageName}`,
              })
              .run();
          }
          dispatch(endLoading());
        }}
      />
      <RichTextEditor
        ref={rteRef}
        onUpdate={({ editor }) => onChange(editor.getHTML())}
        content={value}
        extensions={[
          TableImproved.configure({ resizable: true }),
          TableRow,
          TableHeader,
          TableCell,
          BulletList,
          Document,
          HardBreak,
          ListItem,
          OrderedList,
          Paragraph,
          Text,
          Bold,
          Blockquote,
          Italic,
          Underline,
          Strike,
          CustomLinkExtension.configure({
            autolink: true,
            linkOnPaste: true,
            openOnClick: false,
          }),
          LinkBubbleMenuHandler,
          Gapcursor,
          Heading,
          TextAlign.configure({ types: ["heading", "paragraph", "image"] }),
          TextStyle,
          FontSize,
          HorizontalRule,
          ResizableImage,
          Dropcursor,
          History,
        ]}
        renderControls={() => (
          <MenuControlsContainer>
            <MenuButtonUndo tooltipLabel="Desfazer" />
            <MenuButtonRedo tooltipLabel="Refazer" />
            <MenuDivider />
            <MenuSelectHeading
              aria-label="Tipos de títulos"
              tooltipTitle="Alterar tipo de título"
              labels={{
                empty: "Mudar para…",
                paragraph: "Parágrafo",
                heading1: "Título 1",
                heading2: "Título 2",
                heading3: "Título 3",
                heading4: "Título 4",
                heading5: "Título 5",
                heading6: "Título 6",
              }}
            />
            <MenuDivider />
            <MenuSelectFontSize
              aria-label="Tamanhos de fonte"
              tooltipTitle="Mudar tamanho de fonte"
              unsetOptionLabel="Padrão"
            />
            <MenuDivider />
            <MenuButtonBold tooltipLabel="Negrito" />
            <MenuButtonItalic tooltipLabel="Itálico" />
            <MenuButtonUnderline tooltipLabel="Sublinhado" />
            <MenuButtonStrikethrough tooltipLabel="Tachado" />
            <MenuDivider />
            <MenuButtonEditLink />
            <MenuDivider />
            <MenuSelectTextAlign
              aria-label="Alinhamentos de texto"
              tooltipTitle="Alterar alinhamento do texto"
              options={[
                {
                  value: "left",
                  label: "Alinhar à esquerda",
                  shortcutKeys: ["mod", "Shift", "L"],
                  IconComponent: FormatAlignLeft,
                },
                {
                  value: "center",
                  label: "Centralizar",
                  shortcutKeys: ["mod", "Shift", "E"],
                  IconComponent: FormatAlignCenter,
                },
                {
                  value: "right",
                  label: "Alinhar à direita",
                  shortcutKeys: ["mod", "Shift", "R"],
                  IconComponent: FormatAlignRight,
                },
                {
                  value: "justify",
                  label: "Justificar",
                  shortcutKeys: ["mod", "Shift", "j"],
                  IconComponent: FormatAlignJustify,
                },
              ]}
            />
            <MenuDivider />
            <MenuButtonOrderedList tooltipLabel="Numeração" />
            <MenuButtonBulletedList tooltipLabel="Marcadores" />
            {isTouchDevice() && (
              <>
                <MenuButtonIndent tooltipLabel="Recuar" />
                <MenuButtonUnindent tooltipLabel="Retirar recuo" />
              </>
            )}
            <MenuDivider />
            <MenuButtonBlockquote tooltipLabel="Bloco de citação" />
            <MenuDivider />
            <MenuButtonAddImage
              tooltipLabel="Inserir imagem"
              onClick={() => {
                inputFile.current.click();
              }}
            />

            <MenuDivider />
            <MenuButtonHorizontalRule tooltipLabel="Inserir linha horizontal" />
            <MenuButtonAddTable tooltipLabel="Inserir tabela" />
            <MenuDivider />
            <MenuButtonRemoveFormatting tooltipLabel="Remover formatação embutida" />
          </MenuControlsContainer>
        )}
      >
        {() => (
          <>
            <LinkBubbleMenu
              labels={{
                editLinkAddTitle: "Adicionar link",
                editLinkEditTitle: "Editar link",
                editLinkCancelButtonLabel: "Cancelar",
                editLinkSaveButtonLabel: "Salvar",
                viewLinkEditButtonLabel: "Editar",
                viewLinkRemoveButtonLabel: "Remover",
                editLinkTextInputLabel: "Texto",
              }}
            />
            <TableBubbleMenu
              labels={{
                deleteColumn: "Excluir coluna",
                deleteRow: "Excluir linha",
                deleteTable: "Excluir tabela",
                insertColumnAfter: "Inserir coluna à direita",
                insertColumnBefore: "Inserir coluna à esquerda",
                insertRowAbove: "Inserir linha acima",
                insertRowBelow: "Inserir linha abaixo",
                mergeCells: "Mesclar células",
                splitCell: "Dividir células",
                toggleHeaderCell: "Alternar célula de cabeçalho",
                toggleHeaderColumn: "Alternar coluna de cabeçalho",
                toggleHeaderRow: "Alternar linha de cabeçalho",
              }}
            />
          </>
        )}
      </RichTextEditor>
    </Box>
  );
}
