import { Box } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import CrudLayout from "../layout/crudLayout";
import MainLayout from "../layout/mainLayout";
import { checkUser, selectUser } from "../store/authSlice";
import RecoverPasswordView from "../views/auth/recoverPassword";
import SignInView from "../views/auth/signIn";
import UpdatePasswordView from "../views/auth/updatePassword";
import BrandView from "../views/brand";
import BrandFormView from "../views/brand/form";
import CategoryView from "../views/category";
import CategoryFormView from "../views/category/form";
import CompanyView from "../views/company";
import CompanyFormView from "../views/company/form";
import UserPFView from "../views/userPF";
import UserPFFormView from "../views/userPF/form";
import UserPJView from "../views/userPJ";
import UserPJFormView from "../views/userPJ/form";
import CouponView from "../views/coupon";
import CouponFormView from "../views/coupon/form";
import DashBoardView from "../views/dashboard";
import NotFoundView from "../views/notFound";
import OrderView from "../views/order";
import ProductView from "../views/product";
import ProductFormView from "../views/product/form";
import SettingsView from "../views/settings";
import RolePermissionFormView from "../views/settings/formRolePermission";
import SlidesView from "../views/slides";
import StockView from "../views/stock";
import StockHistoricView from "../views/stock/view";
import StockFormView from "../views/stock/form";
import ProtectedRoute from "./protectedRoute";
import SystemUserView from "../views/systemUser";
import SystemUserFormView from "../views/systemUser/form";
import OrderDetailsView from "../views/order/view";
import ProductReportView from "../views/report/product";
import ProductHistoricView from "../views/report/historicProduct";
import FinanceView from "../views/dashboard/finance";
import SalesView from "../views/dashboard/sales";
import PromotionFormView from "../views/promotion/form";
import PromotionView from "../views/promotion";
import SaleBankSlipView from "../views/financial/saleBankSlip";
import SaleBankSlipFormView from "../views/financial/saleBankSlipForm";
import HistoricPaymentView from "../views/financial/historicPayment";
import AppView from "../views/app";
import PushNew from "../views/pushNotification";
import ContactsView from "../views/contacts";
import ContactsFormView from "../views/contacts/form";
import NewsView from "../views/communications/news";
import NewsFormView from "../views/communications/news/form";
import ComunicadoView from "../views/communications/comunicado";
import ComunicadoFormView from "../views/communications/comunicado/form";
import UniversidadeNewsView from "../views/universidade/noticias";
import UniversidadeNewsFormView from "../views/universidade/noticias/form";
import UniversidadeLinksView from "../views/universidade/links";
import UniversidadeLinksFormView from "../views/universidade/links/form";
import UniversidadeVideoView from "../views/universidade/videos";
import UniversidadeVideoFormView from "../views/universidade/videos/form";
import UniversidadeCursoView from "../views/universidade/cursos";
import UniversidadeCursoFormView from "../views/universidade/cursos/form";
import UniversidadeIntegracaoLogisticaView from "../views/universidade/integracao/logistica";
import UniversidadeIntegracaoLogisticaFormView from "../views/universidade/integracao/logistica/form";
import UniversidadeIntegracaoVendasView from "../views/universidade/integracao/vendas";
import UniversidadeIntegracaoVendasFormView from "../views/universidade/integracao/vendas/form";
import UniversidadeIntegracaoFinanceiroFormView from "../views/universidade/integracao/financeiro/form";
import UniversidadeIntegracaoFinanceiroView from "../views/universidade/integracao/financeiro";
import UniversidadeIntegracaoBIFormView from "../views/universidade/integracao/bi/form";
import UniversidadeIntegracaoBIView from "../views/universidade/integracao/bi";
import RestricaoView from "../views/restricaoVenda";
import RestricaoFormView from "../views/restricaoVenda/form";

const Router = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  useEffect(() => {
    dispatch(checkUser(sessionStorage.getItem("vl_token")));
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute isAllowed={!!user} redirectPath="auth">
              <MainLayout />
            </ProtectedRoute>
          }
        >
          <Route index element={<DashBoardView />} />
          <Route
            element={
              <Box p={3}>
                <Outlet />
              </Box>
            }
          >
            <Route
              path="user"
              element={<CrudLayout title="Usuários" showButton />}
            >
              <Route index element={<SystemUserView />} />
              <Route path="form/:id" element={<SystemUserFormView />} />
              <Route path="form" element={<SystemUserFormView />} />
            </Route>
            <Route
              path="settings"
              element={<CrudLayout title="Configurações" />}
            >
              <Route index element={<SettingsView />} />
              <Route
                path="form-rolepermission/:id"
                element={<RolePermissionFormView />}
              />
              <Route
                path="form-rolepermission"
                element={<RolePermissionFormView />}
              />
            </Route>
            <Route path="slide" element={<CrudLayout title="Slides" />}>
              <Route index element={<SlidesView />} />
            </Route>
            <Route
              path="category"
              element={<CrudLayout title="Categorias" showButton />}
            >
              <Route index element={<CategoryView />} />
              <Route path="form/:id" element={<CategoryFormView />} />
              <Route path="form" element={<CategoryFormView />} />
            </Route>
            <Route
              path="company"
              element={<CrudLayout title="Empresas" showButton />}
            >
              <Route index element={<CompanyView />} />
              <Route path="form/:id" element={<CompanyFormView />} />
              <Route path="form" element={<CompanyFormView />} />
            </Route>
            <Route
              path="brand"
              element={<CrudLayout title="Fabricantes" showButton />}
            >
              <Route index element={<BrandView />} />
              <Route path="form/:id" element={<BrandFormView />} />
              <Route path="form" element={<BrandFormView />} />
            </Route>
            <Route
              path="brand/:idBrand/restricao-venda"
              element={<CrudLayout title="Restrições de Vendas" showButton />}
            >
              <Route index element={<RestricaoView />} />
              <Route path="form/:id" element={<RestricaoFormView />} />
              <Route path="form" element={<RestricaoFormView />} />
            </Route>
            <Route
              path="product"
              element={<CrudLayout title="Produtos" showButton />}
            >
              <Route index element={<ProductView />} />
              <Route path="form/:id" element={<ProductFormView />} />
              <Route path="form" element={<ProductFormView />} />
            </Route>
            <Route
              path="stock"
              element={<CrudLayout title="Estoque de Produto" />}
            >
              <Route index element={<StockView />} />
              <Route path="form/:id" element={<StockFormView />} />
              <Route path="view/:id" element={<StockHistoricView />} />
            </Route>
            <Route
              path="coupon"
              element={<CrudLayout title="Cupons de Desconto" showButton />}
            >
              <Route index element={<CouponView />} />
              <Route path="form/:id" element={<CouponFormView />} />
              <Route path="form" element={<CouponFormView />} />
            </Route>
            <Route path="order">
              <Route index element={<OrderView />} />
              <Route path="view/:id" element={<OrderDetailsView />} />
            </Route>
            <Route
              path="client-pj"
              element={
                <CrudLayout title="Clientes (Pessoa Jurídica)" showButton />
              }
            >
              <Route index element={<UserPJView />} />
              <Route path="form/:id" element={<UserPJFormView />} />
              <Route path="form" element={<UserPJFormView />} />
            </Route>
            <Route
              path="client-pf"
              element={
                <CrudLayout title="Clientes (Pessoa Física)" showButton />
              }
            >
              <Route index element={<UserPFView />} />
              <Route path="form/:id" element={<UserPFFormView />} />
              <Route path="form" element={<UserPFFormView />} />
            </Route>
            <Route
              path="stock-listing"
              element={<CrudLayout title="Listagem de Estoque" />}
            >
              <Route index element={<ProductReportView />} />
            </Route>
            <Route
              path="product-historic"
              element={<CrudLayout title="Histórico de Produtos" />}
            >
              <Route index element={<ProductHistoricView />} />
            </Route>
            <Route
              path="promotion"
              element={
                <CrudLayout
                  title="Promoções Ativas"
                  titleForm="Criar Promoção"
                  showButton
                />
              }
            >
              <Route index element={<PromotionView />} />
              <Route path="form" element={<PromotionFormView />} />
            </Route>
            <Route
              path="sale-bankslip"
              element={<CrudLayout title="Tipo de Venda (Boleto)" showButton />}
            >
              <Route index element={<SaleBankSlipView />} />
              <Route path="form/:id" element={<SaleBankSlipFormView />} />
              <Route path="form" element={<SaleBankSlipFormView />} />
            </Route>
            <Route
              path="payment-history"
              element={<CrudLayout title="Histórico de Pagamento" />}
            >
              <Route index element={<HistoricPaymentView />} />
            </Route>
            <Route path="finance" element={<FinanceView />} />
            <Route path="sales" element={<SalesView />} />
            <Route
              path="push-notification"
              element={<CrudLayout title="Notificão Push" />}
            >
              <Route index element={<PushNew />} />
            </Route>
            <Route
              path="contacts"
              element={<CrudLayout title="Agenda Telefônica" showButton />}
            >
              <Route index element={<ContactsView />} />
              <Route path="form/:id" element={<ContactsFormView />} />
              <Route path="form" element={<ContactsFormView />} />
            </Route>
            <Route
              path="news"
              element={<CrudLayout title="Notícias" showButton />}
            >
              <Route index element={<NewsView />} />
              <Route path="form/:id" element={<NewsFormView />} />
              <Route path="form" element={<NewsFormView />} />
            </Route>
            <Route
              path="comunicado"
              element={<CrudLayout title="Comunicados" showButton />}
            >
              <Route index element={<ComunicadoView />} />
              <Route path="form/:id" element={<ComunicadoFormView />} />
              <Route path="form" element={<ComunicadoFormView />} />
            </Route>
            <Route
              path="universidade/news"
              element={<CrudLayout title="Artigos e Notícias" showButton />}
            >
              <Route index element={<UniversidadeNewsView />} />
              <Route path="form/:id" element={<UniversidadeNewsFormView />} />
              <Route path="form" element={<UniversidadeNewsFormView />} />
            </Route>
            <Route
              path="universidade/links"
              element={<CrudLayout title="Cursos EAD Livre" showButton />}
            >
              <Route index element={<UniversidadeLinksView />} />
              <Route path="form/:id" element={<UniversidadeLinksFormView />} />
              <Route path="form" element={<UniversidadeLinksFormView />} />
            </Route>
            <Route
              path="universidade/video"
              element={<CrudLayout title="Vídeos" showButton />}
            >
              <Route index element={<UniversidadeVideoView />} />
              <Route path="form/:id" element={<UniversidadeVideoFormView />} />
              <Route path="form" element={<UniversidadeVideoFormView />} />
            </Route>
            <Route
              path="universidade/cursos"
              element={<CrudLayout title="Cursos" showButton />}
            >
              <Route index element={<UniversidadeCursoView />} />
              <Route path="form/:id" element={<UniversidadeCursoFormView />} />
              <Route path="form" element={<UniversidadeCursoFormView />} />
            </Route>
            <Route
              path="universidade/integracao/logistica"
              element={<CrudLayout title="Integração - Logística" showButton />}
            >
              <Route index element={<UniversidadeIntegracaoLogisticaView />} />
              <Route
                path="form/:id"
                element={<UniversidadeIntegracaoLogisticaFormView />}
              />
              <Route
                path="form"
                element={<UniversidadeIntegracaoLogisticaFormView />}
              />
            </Route>
            <Route
              path="universidade/integracao/bi"
              element={
                <CrudLayout
                  title="Integração - Business Intelligence"
                  showButton
                />
              }
            >
              <Route index element={<UniversidadeIntegracaoBIView />} />
              <Route
                path="form/:id"
                element={<UniversidadeIntegracaoBIFormView />}
              />
              <Route
                path="form"
                element={<UniversidadeIntegracaoBIFormView />}
              />
            </Route>
            <Route
              path="universidade/integracao/financeiro"
              element={
                <CrudLayout title="Integração - Financeiro" showButton />
              }
            >
              <Route index element={<UniversidadeIntegracaoFinanceiroView />} />
              <Route
                path="form/:id"
                element={<UniversidadeIntegracaoFinanceiroFormView />}
              />
              <Route
                path="form"
                element={<UniversidadeIntegracaoFinanceiroFormView />}
              />
            </Route>
            <Route
              path="universidade/integracao/vendas"
              element={<CrudLayout title="Integração - Vendas" showButton />}
            >
              <Route index element={<UniversidadeIntegracaoVendasView />} />
              <Route
                path="form/:id"
                element={<UniversidadeIntegracaoVendasFormView />}
              />
              <Route
                path="form"
                element={<UniversidadeIntegracaoVendasFormView />}
              />
            </Route>
          </Route>
        </Route>

        <Route
          path="auth"
          element={<ProtectedRoute isAllowed={!user} redirectPath="/" />}
        >
          <Route index element={<SignInView />} />
          <Route path="recover-password" element={<RecoverPasswordView />} />
          <Route
            path="update-password/:token"
            element={<UpdatePasswordView />}
          />
        </Route>

        <Route path="app">
          <Route index element={<AppView />} />
        </Route>

        <Route path="*" element={<NotFoundView />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
