import api from "../common/api";

const getAll = () => api.get("/system-user");

const get = (id) => api.get(`/system-user/${id}`);

const create = (data) => api.post("/system-user", data);

const update = (data) => api.put(`/system-user/${data.id}`, data);

const remove = (id) => api.delete(`/system-user/${id}`);

const systemUserService = { getAll, get, create, update, remove };

export default systemUserService;
