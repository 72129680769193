import { AddOutlined } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const HeaderView = ({
  title,
  buttonTo = undefined,
  showButton = undefined,
}) => (
  <Grid
    container
    justifyContent="space-between"
    alignItems="center"
    marginBottom={4}
  >
    <Grid item>
      <Typography variant="h5" component="h1" lineHeight={1.55}>
        {title}
      </Typography>
    </Grid>
    <Grid item>
      {buttonTo && showButton && (
        <Button
          startIcon={<AddOutlined />}
          variant="contained"
          component={Link}
          to={buttonTo}
          color="primary"
          sx={(theme) => ({
            [theme.breakpoints.down("xs")]: {
              padding: "4px 10px",
              fontSize: "0.8125rem",
            },
          })}
        >
          Adicionar
        </Button>
      )}
    </Grid>
  </Grid>
);
export default HeaderView;
