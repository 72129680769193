import api from "../common/api";

const getAll = () => api.get("/coupon");

const get = (id) => api.get(`/coupon/${id}`);

const create = (data) => api.post("/coupon", data);

const update = (data) => api.put(`/coupon/${data.id}`, data);

const remove = (id) => api.delete(`/coupon/${id}`);

const couponService = { getAll, get, create, update, remove };

export default couponService;
