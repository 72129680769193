/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/iframe-has-title */
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import yup from "../../../../common/validator";
import { useDispatch } from "react-redux";
import { endLoading, startLoading } from "../../../../store/loadingSlice";
import { getErrorMessage, youtubeParser } from "../../../../utils";
import TextEditor from "../../../../components/textEditor";
import apiService from "../../../../services/universidadeIntegracaoBI";
import { Delete } from "@mui/icons-material";

const validation = {
  title: yup.string().required(),
  subTitle: yup.string(),
  publish: yup.boolean().required(),
  text: yup.string().nullable(),
};

const UniversidadeIntegracaoBIFormView = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(yup.object().shape(validation)),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [item, setItem] = useState();

  const getData = useCallback(async () => {
    try {
      dispatch(startLoading());
      if (id) {
        const { data } = await apiService.get(id);
        setItem(data);
        reset({
          title: data.title,
          subTitle: data.subTitle,
          publish: data.publish,
          text: data.text,
        });
      } else {
        const { data } = await apiService.create();
        navigate(`${data.id}`, { replace: true });
      }
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
      navigate("..", { replace: true });
    } finally {
      dispatch(endLoading());
    }
  }, [dispatch, enqueueSnackbar, id, navigate, reset]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onSubmit = async (data) => {
    try {
      dispatch(startLoading());
      await apiService.update(id, data);
      enqueueSnackbar(`Salvo com sucesso`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  const onAddVideo = async (title, url) => {
    try {
      dispatch(startLoading());
      await apiService.addVideo(id, { title, url });
      const { data } = await apiService.get(id);
      setItem(data);
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  const onAddArquivo = async (files) => {
    try {
      dispatch(startLoading());

      const formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }

      await apiService.addArquivo(id, formData);

      const { data } = await apiService.get(id);
      setItem(data);
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  const onRemoveVideo = async (videoId) => {
    try {
      dispatch(startLoading());
      await apiService.removeVideo(id, videoId);
      const { data } = await apiService.get(id);
      setItem(data);
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  const onRemoveArquivo = async (arquivoId) => {
    try {
      dispatch(startLoading());
      await apiService.removeArquivo(id, arquivoId);
      const { data } = await apiService.get(id);
      setItem(data);
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper variant="outlined">
              <Typography
                sx={{ fontSize: "1rem", fontWeight: "500", margin: 2 }}
              >
                Editar detalhes
              </Typography>
              <Divider sx={{ marginBottom: 1 }} />
              <form onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="title"
                        defaultValue=""
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            label="Título"
                            fullWidth
                            error={!!error}
                            helperText={error?.message}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="subTitle"
                        defaultValue=""
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            label="Subtítulo"
                            fullWidth
                            error={!!error}
                            helperText={error?.message}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="publish"
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Switch
                                color="primary"
                                checked={field.value}
                                {...field}
                              />
                            }
                            label="Publicar"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {item && (
                        <Controller
                          name="text"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextEditor
                              onChange={field.onChange}
                              value={field.value}
                            />
                          )}
                        />
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions sx={{ justifyContent: "flex-end" }}>
                  <Button type="submit">Salvar</Button>
                </CardActions>
              </form>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper variant="outlined">
              <Typography
                sx={{ fontSize: "1rem", fontWeight: "500", margin: 2 }}
              >
                Vídeos
              </Typography>
              <Divider sx={{ marginBottom: 1 }} />
              <CardContent>
                {item?.videos?.length ? (
                  <Box
                    sx={{
                      display: "flex",
                      overflowX: "auto",
                      gap: 1.5,
                    }}
                  >
                    {item.videos.map((video, i) => (
                      <Box key={`video-${i}`}>
                        <Box
                          width={"350px"}
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Typography variant="body2" fontWeight={500} noWrap>
                            {video.title}
                          </Typography>
                          <IconButton
                            color="error"
                            size="small"
                            sx={{ marginLeft: 0.5 }}
                            onClick={() => {
                              if (
                                confirm(
                                  "Por favor, confirme que deseja excluir este vídeo."
                                )
                              ) {
                                onRemoveVideo(video.id);
                              }
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Box>
                        <iframe
                          width="350"
                          height="200"
                          src={`https://www.youtube.com/embed/${youtubeParser(
                            video.url
                          )}`}
                          allowFullScreen
                          style={{ border: "none", borderRadius: 5 }}
                        />
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <Typography
                    sx={{ textAlign: "center", pt: 5 }}
                    variant="body2"
                  >
                    Nenhum vídeo adicionado
                  </Typography>
                )}
              </CardContent>
              <CardActions sx={{ justifyContent: "flex-end" }}>
                <Button
                  onClick={() => {
                    const title = prompt("Informe o título do vídeo.");
                    const url = prompt("Informe a url do vídeo.");
                    if (title && url) {
                      onAddVideo(title, url);
                    }
                  }}
                >
                  Adicionar
                </Button>
              </CardActions>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper variant="outlined">
              <Typography
                sx={{ fontSize: "1rem", fontWeight: "500", margin: 2 }}
              >
                Arquivos
              </Typography>
              <Divider sx={{ marginBottom: 1 }} />
              <CardContent>
                {item?.arquivos?.length ? (
                  <Box
                    sx={{
                      display: "flex",
                      overflowX: "auto",
                      gap: 1.5,
                      paddingBottom: 1,
                    }}
                  >
                    {item.arquivos.map((arquivo, i) => (
                      <Box key={`arquivo-${i}`}>
                        <Box
                          width={"300px"}
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Typography variant="body2" fontWeight={500} noWrap>
                            {arquivo.name}
                          </Typography>
                          <IconButton
                            color="error"
                            size="small"
                            sx={{ marginLeft: 0.5 }}
                            onClick={() => {
                              if (
                                confirm(
                                  "Por favor, confirme que deseja excluir este arquivo."
                                )
                              ) {
                                onRemoveArquivo(arquivo.id);
                              }
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Box>
                        <Box
                          width={"300px"}
                          height={"200px"}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          bgcolor={"rgba(160, 205, 81, 0.2)"}
                          borderRadius={"5px"}
                          sx={{ cursor: "pointer" }}
                          onClick={() =>
                            window.open(
                              `${process.env.REACT_APP_URL_API}/document/${arquivo.file}`,
                              "_black"
                            )
                          }
                        >
                          <img src="/pdf.png" alt="pdf" height={"100px"} />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <Typography
                    sx={{ textAlign: "center", pt: 5 }}
                    variant="body2"
                  >
                    Nenhum arquivo adicionado
                  </Typography>
                )}
              </CardContent>
              <CardActions sx={{ justifyContent: "flex-end" }}>
                <Button component="label">
                  Adicionar
                  <input
                    hidden
                    accept="application/pdf"
                    multiple
                    type="file"
                    name="files"
                    onChange={(event) => {
                      console.log(event.target.files);
                      if (event.target.files) {
                        onAddArquivo(event.target.files);
                      }
                    }}
                  />
                </Button>
              </CardActions>
            </Paper>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UniversidadeIntegracaoBIFormView;
