import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import yup from "../../../common/validator";
import { useDispatch } from "react-redux";
import { endLoading, startLoading } from "../../../store/loadingSlice";
import { getErrorMessage } from "../../../utils";
import apiService from "../../../services/universidadeLink";

const validation = {
  title: yup.string().required(),
  desc: yup.string(),
  url: yup.string(),
  publish: yup.boolean().required(),
};

const UniversidadeLinksFormView = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(yup.object().shape(validation)),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getData = useCallback(async () => {
    try {
      dispatch(startLoading());
      if (id) {
        const { data } = await apiService.get(id);
        reset(data);
      }
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
      navigate("..", { replace: true });
    } finally {
      dispatch(endLoading());
    }
  }, [dispatch, enqueueSnackbar, id, navigate, reset]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onSubmit = async (data) => {
    try {
      dispatch(startLoading());

      if (id) {
        await apiService.update(id, data);
      } else {
        await apiService.create(data);
      }
      enqueueSnackbar(`${id ? "Edição" : "Cadastro"} realizado com sucesso`, {
        variant: "success",
      });
      navigate("..");
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="title"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label="Título"
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="desc"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label="Descrição"
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="url"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label="Url"
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="publish"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={field.value}
                        {...field}
                      />
                    }
                    label="Publicar"
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button component={Link} to=".." color="error">
            Cancelar
          </Button>
          <Button type="submit">Salvar</Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default UniversidadeLinksFormView;
