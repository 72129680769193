import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import DataGrid from "../../components/dataGrid";
import { getErrorMessage } from "../../utils";
import { Avatar, Box, Typography } from "@mui/material";
import productService from "../../services/product";

const ProductReportView = () => {
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loadingData, setLoadingData] = useState(false);
  const columns = [
    {
      field: "name",
      headerName: "Produto",
      flex: 4,
      minWidth: 150,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" width="100%">
          <Avatar
            alt={params.row.name}
            src={`${process.env.REACT_APP_URL_API}/image/${params.row.photos[0].filename}`}
            sx={{ width: 56, height: 56 }}
          />
          <Typography variant="body2" pl={1.5}>
            {params.row.name}
          </Typography>
        </Box>
      ),
    },
    {
      field: "stock",
      headerName: "Quantidade no estoque",
      flex: 1,
      minWidth: 150,
      type: "number",
    },
  ];

  const getData = useCallback(async () => {
    try {
      setLoadingData(true);
      const { data } = await productService.getAll();
      setData(data);
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      setLoadingData(false);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <DataGrid
      rows={data}
      columns={columns}
      loading={loadingData}
      rowHeight={60}
    />
  );
};

export default ProductReportView;
